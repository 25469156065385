import axios, {
  AxiosRequestConfig,
  AxiosResponse
} from "axios";
import { TLogin } from "pages/Login/d";
import {
  LocalStorage,
  STORAGE_TOKEN
} from "utils/LocalStorage";
import store from 'store/index'
import {
  _actionAuthClearUser,
  _actionAuthSetUser
} from "store/auth/actions";
import {
  _actionCaslSetRules,
  _actionCaslFetchUserPermissions
} from "store/casl/action";
import {
  RefObject,
  MutableRefObject
} from "react";

export const dataToken = {} as any

const getToken = () => LocalStorage.getData(STORAGE_TOKEN)

const logout = () => {
  dataToken.token = undefined
  LocalStorage.removeItem(STORAGE_TOKEN)
  store.dispatch(_actionAuthClearUser())
}

const getNewToken = async () => {
  const data = getToken()
  const refreshToken = data?.token
  try {
    const response = await request({
      url: 'refresh-token',
      data: {
        token: refreshToken
      }
    }) as any
    if (!response.data?.ok) throw Error('Invalid Token')
    dataToken.token = response.data.token || ''
    store.dispatch(_actionAuthSetUser({
      forbiddenRoutes: response.data.forbiddenRoutes
    } as any))
    store.dispatch(_actionCaslSetRules({
      rules: response.data.frontedPermissions,
      sha: response.data.frontedSha
    }))
  } catch (e) {
    /** logout */
    logout()
  }
}

const baseUrl = (() => {
  const url = window.location.href
  return (url.includes('localhost') || url.includes('127.0.0.1')) ? 'http://localhost:5000' : 'https://erp.cheapr.com/api/'
  // return (url.includes('localhost') || url.includes('127.0.0.1')) ? 'http://localhost:5000' : 'http://146.190.146.69/api/'
})()

export const _request = async (data: any): Promise<any> => new Promise(async (resolve, reject) => {
  axios(data)
    .then((response) => resolve(response))
    .catch((error) => reject(error))
})

const request = async ({
  headers,
  baseURL = baseUrl,
  url,
  method = 'POST',
  data = {},
  params = {},
  timeout = 30000,
  signal
}: AxiosRequestConfig): Promise<AxiosResponse> => {
  const _url = url as string
  const isNeedToken = (() => {
    if (_url.startsWith('login') || _url.startsWith('refresh-token')) return false
    return true
  })()
  let header = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...headers,
    Authorization: isNeedToken && `Bearer ${dataToken?.token}`,
    'Access-Control-Expose-Headers': 'fronted-permission-sha'
  }
  return _request({ withCredentials: true, headers: header, baseURL, url, method, data, timeout, params, signal })
}

export const axiosRequest = async (requestData: any) => {
  try {
    const data = await request(requestData)
    store.dispatch(_actionCaslFetchUserPermissions(data.headers['fronted-permission-sha']))
    return data
  } catch (e: any) {
    if (e?.response?.status === 401) {
      await getNewToken()
      try {
        return request(requestData)
      } catch (e) {
        /** show notification */
        //console.log(e)
        return
      }
    }
    if (e?.code === "ERR_NETWORK") {
      logout()
      return;
    }
    throw e
  }
}

export const API = {
  post: (url: string, data: any, signal?: AbortController) => axiosRequest({
    url,
    data,
    signal: signal?.signal
  }),
  get: (url: string, params?: any, signal?: AbortController) => axiosRequest({
    url,
    method: 'GET',
    params,
    signal: signal?.signal
  })
}

export const rootApi = '/modelsapi'

export default {

  async login(data: TLogin) {
    const response = await API.post('/login', data) as any
    return response?.data || {}
  },

  async getModelsUpdate(requestData: any) {
    return API.post('/rest-complex-api/get-new-data', { ...requestData })
  },

  async getModelAllCount(model: any, data: any, isPost = false, refForAbortSignal?: MutableRefObject<any>) {
    const controller = new AbortController();
    if (refForAbortSignal) refForAbortSignal.current = controller
    if (!isPost) {
      const filter = Buffer.from(JSON.stringify(data)).toString('base64')
      const response = await API.get(`${rootApi}/${model}/select-all-count`, {
        filter
      }, controller) as any
      return response.data
    }

    const response = await API.post(`${rootApi}/${model}/select-all-count`, {
      ...data
    }, controller) as any
    return response.data
  },
  async getAll(model: string) {
    return await API.get(`${rootApi}/${model}`, {})
  },
  async getDistinct(model: string, requestData: { column: string }) {
    const response = await API.post(`${rootApi}/${model}/select-distinct`, { ...requestData }) as any
    return response?.data || null
  },
  async getModel(model: string, id: number) {
    return (await API.get(`${rootApi}/${model}/${id}`))?.data?.result || undefined
  },
  async updateModel(model: string, id: number, requestData: any) {
    return (await API.post(`${rootApi}/${model}/updateModel/${id}`, { ...requestData }))?.data?.result || undefined
  },

  async getOpenAIRecommendations(requestData: any) {
    return (await API.post('open-ai/get-recommendations-same-make', { ...requestData }))?.data?.data || undefined
  },

  async updateAsin(model: string, requestData: { epid: string, asin: string }) {
    await API.post(`${rootApi}/${model}/updateAsin`, { ...requestData }) // Shouldn't have a return value... Have a way to deal with errors? 
  },

  async updateFilled(model: string, requestData: { sbOrderId: number, filled: number }) {
    await API.post(`${rootApi}/${model}/updateFilled`, { ...requestData })
  },

  async upsertRow(model: string, requestData: any) {
    console.log("About to insert this request: ", requestData)
    return (await API.post(`${rootApi}/${model}/upsertRow`, { ...requestData }))
  }

}
