import { useDispatch } from "react-redux";
import { getSellers } from "api/sellers";
import { useCallback, useState } from "react";
import { _actionSetSellers } from "store/sellers/action";


const useFetchSellers = () => {

    const [loading, setLoading] = useState<boolean>(false)
    const dispatch = useDispatch()


    const fetchSellers = useCallback(async () => {
        try {
            setLoading(true)
            const result = await getSellers() as any
            dispatch(_actionSetSellers(result))
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }, [])

    return {
        fetchSellers,
        isLoading: loading
    }
}


export default useFetchSellers
